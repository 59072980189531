<template>
  <div>

    <!-- Table Container Card -->
    <b-card no-body>
      <div class="m-50">
        <!-- Table Top -->
        <b-row class="justify-content-between">
          <div class="d-flex">
            <!-- Per Page -->
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-25"
            />

            <!-- Plan Periyot            -->
            <v-select
              id="i-plan"
              v-model="filter_plan_period"
              :options="filter_plan_period_options"
              label="title"
              :clearable="false"
              class="mx-25"
              placeholder="Periyot"
            >
              <template #option="{ title, icon }" />
            </v-select>

            <!-- Plan Type            -->
            <v-select
              id="i-plan"
              v-model="filter_plan"
              :options="filter_plan_options"
              label="title"
              :clearable="false"
              class="mx-25"
            >
              <template #option="{ title, icon }" />
            </v-select>
          </div>

          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              size="sm"
              class="d-inline-block mr-1"
              :placeholder="$t('core.search')"
            />
            <b-button
              v-b-modal.new_record_modal
              size="sm"
              variant="primary"
            >
              <span class="text-nowrap">{{ $t('core.btn_new_add') }}</span>
            </b-button>
          </div>
        </b-row>

      </div>
      <b-table
        responsive
        :items="table_data"
        :fields="tableColumns"
        primary-key="id"
        class="text-nowrap"
        show-empty
        style="min-height: 500px"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :empty-text="$t('core.no_records_found')"
        small
        striped
      >
        <!-- Column: country -->
        <template #cell(plan)="data">
          {{ data.item.plan == null ? '' : data.item.plan.plan_tag.toUpperCase() }}
        </template>

        <!-- Column: company_name -->
        <template #cell(company_name)="data">
          <b-link :to="{ name: 'customers-management', params: { id: data.item.id }}">
            {{ data.item.company_name }}
          </b-link>
        </template>

        <!-- Column: register_country -->
        <template #cell(register_country)="data">
          <span v-if="data.item.register_country">{{ data.item.register_country.emoji }} {{ data.item.register_country.name }} </span>
        </template>
        <!-- Column: company_name -->
        <template #cell(plan_country)="data">
          <span v-if="data.item.plan_country">{{ data.item.plan_country.emoji }} {{ data.item.plan_country.name }} </span>
        </template>

        <!-- Column: Actions -->
        <template #cell(id)="data">
          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="$router.push({ name: 'customers-management', params: { id: data.item.id }})">
                <feather-icon icon="SlidersIcon" />
                <span class="align-middle ml-50">Müşteri Yönetimi</span>
              </b-dropdown-item>
              <b-dropdown-item @click="rowUpdateModal(data.item.id, )">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Düzenle</span>
              </b-dropdown-item>
              <!--              <b-dropdown-item @click="rowDeleteModal(data.item.id)">-->
              <!--                <feather-icon icon="TrashIcon" />-->
              <!--                <span class="align-middle ml-50">Sil</span>-->
              <!--              </b-dropdown-item>-->
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
          <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BModal, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BCardHeader, BCardBody, VBModal, BCardText, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import flatPickr from 'vue-flatpickr-component'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Multiselect from 'vue-multiselect'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BModal,
    BCardHeader,
    BCardBody,
    BCard,
    BRow,
    BCol,
    BLink,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BCardText,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BPagination,
    flatPickr,
    DateRangePicker,
    vSelect,
    Multiselect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      selected_row_id: null,
      perPageOptions: [25, 50, 100, 500, 1000],
      sortBy: null,
      isSortDirDesc: null,
      searchQuery: null,
      roleOptions: [],
      table_data: [],
      tableColumns: [
        { key: 'company_name', label: 'Firma Adı', sortable: true },
        { key: 'register_country', label: 'Kayıt Ülke', sortable: true },
        { key: 'plan', label: 'Plan', sortable: true },
        { key: 'plan_period', label: 'Plan Periyot', sortable: true },
        { key: 'plan_country', label: 'Plan Ülke', sortable: true },
        { key: 'total_user', label: 'Toplam Kullanıcı Sayısı', sortable: true },
        { key: 'created', label: 'Kayıt Tarihi', sortable: true },
        { key: 'id', label: '#' },
      ],
      currentPage: 1,
      totalRows: 0,
      perPage: 25,
      filter_plan: { title: 'Hepsi', value: null },
      filter_plan_options: [
        { title: 'Hepsi', value: null },
        { title: 'FREE', value: 'free' },
        { title: 'BASIC', value: 'basic' },
        { title: 'STANDART', value: 'standart' },
        { title: 'PRO', value: 'pro' },
        { title: 'ENTERPRISE', value: 'enterprise' },
      ],
      filter_plan_period: { title: 'Hepsi', value: null },
      filter_plan_period_options: [
        { title: 'Hepsi', value: null },
        { title: 'ANNUAL', value: 'annually' },
        { title: 'MONTHLY', value: 'monthly' },
      ],
    }
  },
  watch: {
    filter_plan_period: { handler() { this.getTableData() } },
    filter_plan: { handler() { this.getTableData() } },
    currentPage: { handler() { this.getTableData() } },
    sortBy: { handler() { this.getTableData() } },
    isSortDirDesc: { handler() { this.getTableData() } },
    searchQuery: {
      handler() {
        this.getTableData()
      },
    },
  },
  created() {
    this.getTableData()
  },
  methods: {
    getTableData() {
      useJwt.getCustomerList({
        // center_type: this.filter_center_type.value,
        page: this.currentPage,
        page_size: this.perPage,
        search: this.searchQuery,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        plan__plan_tag: this.filter_plan.value,
        plan_period: this.filter_plan_period.value,
      })
        .then(response => {
          this.table_data = response.data.results
          this.totalRows = response.data.count
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
  setup() {},
}
</script>
<style src="../../../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
</style>
